/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages
      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

  $(document).ready(function(){
    $('.bxslider').bxSlider({
      minSlides: 2,
      maxSlides: 6,
      slideWidth: 160,
      slideMargin:10,
      pager:false,
      nextText:'<span class="glyphicon glyphicon-arrow-right" aria-hidden="true"></span>',
      prevText:'<span class="glyphicon glyphicon-arrow-left" aria-hidden="true"></span>'
    });
  });

  $(document).ready(function() {
    var $profiles = $('.staff-profiles');
    var $buttons = $('.staff-boxes');
    $buttons.find('.team-box').on('click', function(e) {
      e.preventDefault();
      var $button = $(this);
      var $id = $button.attr('class').replace(/col-lg-2 col-md-3 col-sm-4 col-xs-6 team-box staff-no/, '');
      var $profile = $profiles.find('.profile-no'+$id);
      
      if (!$profile.is(':visible')){
        $profile.slideToggle(function(){
          //$("html, body").animate({scrollTop: $profile.offset().top -85});
        });
      } else {
        $profile.slideToggle(function(){
          //$("html, body").animate({scrollTop: $profile.parent().offset().top -85});
        });
      }
      
      $profile.siblings(":visible").slideUp();
      
      $profiles.find('.close-button').click(function(e) {
        $profiles.find('.staff-profile:visible').slideUp();
      });
    });

    $( ".client-portal #loginform .input" ).addClass( "form-control" );
    $( ".client-portal #loginform #wp-submit" ).addClass( "btn btn-primary btn-purple" );

  });



  $(function() {
      $('.news-box').matchHeight();
      $('.match-height').matchHeight({
          byRow: false
      });
      $('.home-sector-study .col-md-5').matchHeight({
          target: $('.home-sector-study .col-md-7')
      });
      $('.match-height-1').matchHeight({
          byRow: false
      });
      $('.match-height-2').matchHeight({
          byRow: false
      });
      // $('.match-height-3').matchHeight({
      //     byRow: false
      // });
      // $('.match-height-4').matchHeight({
      //     byRow: false
      // });
      // $('.match-height-5').matchHeight({
      //     byRow: false
      // });
      // $('.match-height-6').matchHeight({
      //     byRow: false
      // });
      // $('.match-height-7').matchHeight({
      //     byRow: false
      // });
      // $('.match-height-8').matchHeight({
      //     byRow: false
      // });
      // $('.match-height-9').matchHeight({
      //     byRow: false
      // });
      // $('.match-height-10').matchHeight({
      //     byRow: false
      // });
      // $('.match-height-11').matchHeight({
      //     byRow: false
      // });
      // $('.match-height-12').matchHeight({
      //     byRow: false
      // });
      // $('.match-height-13').matchHeight({
      //     byRow: false
      // });
       $('.sector-grid .grid-box').matchHeight({
          byRow: false
      });
  });

  // Remove Phantom Text Nodes
  function clean(node)
  {
    for(var n = 0; n < node.childNodes.length; n ++)
    {
      var child = node.childNodes[n];
      if( child.nodeType === 8 || (child.nodeType === 3 && !/\S/.test(child.nodeValue)) ) {
        node.removeChild(child);
        n --;
      } else if(child.nodeType === 1) {
        clean(child);
      }
    }
  }
  clean(document);

  $(function() {
    //$('.accred-logo').matchHeight();
  });

})(jQuery); // Fully reference jQuery after this point.
